import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { TranscodingProfilesService } from "../transcoding-profiles.service";
import { TranscodingProfile } from "../transcoding-profile";

@Injectable({
    providedIn: "root"
})
export class TranscodingProfileListResolverService implements Resolve<TranscodingProfile[]> {
    constructor(private tps: TranscodingProfilesService) {}

    resolve(): Observable<TranscodingProfile[]> | Observable<never> {
        return new Observable((observe: Subscriber<TranscodingProfile[]>) => {
            this.tps.refreshTranscodingProfiles().subscribe((transcodingProfiles: TranscodingProfile[]) => {
                observe.next(transcodingProfiles);
                observe.complete();
            });
        });
    }
}
