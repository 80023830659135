<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div zmid="title" class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_TRANSCODING_PROFILE" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_TRANSCODING_PROFILE" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_TRANSCODING_PROFILE" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <!-- Name -->
                    <div class="form-group mt-3">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="transcodingProfile.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ transcodingProfileNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <section id="video">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'VIDEO' | translate }}" [ngClass]="{ 'status-error': form.submitted && videoHasErrors(form) }">
                                {{ "VIDEO" | translate }}
                            </h3>
                        </div>
                        <!-- Video -->
                        <fieldset class="form-group">
                            <legend class="sr-only" for="videoMode">{{ "VIDEO" | translate }}</legend>
                            <mat-button-toggle-group name="videoMode" aria-label="video mode" [(ngModel)]="videoMode" (change)="onVideoModeChange()">
                                <mat-button-toggle [value]="0">{{ "TRANSCODE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="1">{{ "USE_ORIGINAL" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="2">{{ "REMOVE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Codec -->
                        <fieldset class="form-group" *ngIf="videoMode === 0">
                            <legend for="codec">{{ "CODEC" | translate }}</legend>
                            <mat-button-toggle-group
                                name="codec"
                                aria-label="codec"
                                [(ngModel)]="transcodingProfile.video_codec"
                                (change)="updateEncodingProfile()"
                            >
                                <mat-button-toggle value="h264">{{ "H.264" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="h265">{{ "H.265" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="mpeg2">{{ "MPEG2" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Encoding Profile -->
                        <div class="form-group" *ngIf="videoMode === 0 && transcodingProfile.video_codec === 'h264'">
                            <label for="encoding_profile">
                                {{ "ENCODING_PROFILE" | translate }}
                            </label>
                            <ng-select
                                id="encoding_profile"
                                name="encoding_profile"
                                [items]="consts.selectVideoProfiles"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ENCODING_PROFILE' | translate }}"
                                [(ngModel)]="transcodingProfile.encoding_profile"
                                #encoding_profile="ngModel"
                                (ngModelChange)="encodingProfileChanged()"
                                required
                            >
                            </ng-select>
                        </div>
                        <div class="form-group" *ngIf="videoMode === 0 && transcodingProfile.video_codec === 'h265'">
                            <label for="encoding_profile">
                                {{ "ENCODING_PROFILE" | translate }}
                            </label>
                            <ng-select
                                id="encoding_profile"
                                name="encoding_profile"
                                [items]="consts.selectVideoProfilesH265"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ENCODING_PROFILE' | translate }}"
                                [(ngModel)]="transcodingProfile.encoding_profile"
                                #encoding_profile="ngModel"
                                (ngModelChange)="encodingProfileChanged()"
                                required
                            >
                            </ng-select>
                        </div>
                        <div class="form-group" *ngIf="videoMode === 0 && transcodingProfile.video_codec === 'mpeg2'">
                            <label for="encoding_profile">
                                {{ "ENCODING_PROFILE" | translate }}
                            </label>
                            <ng-select
                                id="encoding_profile"
                                name="encoding_profile"
                                [items]="consts.selectVideoProfilesMpeg2"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ENCODING_PROFILE' | translate }}"
                                [(ngModel)]="transcodingProfile.encoding_profile"
                                #encoding_profile="ngModel"
                                (ngModelChange)="encodingProfileChanged()"
                                required
                            >
                            </ng-select>
                        </div>
                        <!-- Resolution -->
                        <fieldset class="bordered mb-3" *ngIf="videoMode === 0">
                            <legend>{{ "RESOLUTION" | translate }}</legend>
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="sourceResolution"
                                        name="sourceResolution"
                                        [(ngModel)]="transcodingProfile.source_resolution"
                                        (ngModelChange)="updateSourceAspectRatio()"
                                    />
                                    <label class="form-check-label" for="sourceResolution">{{ "USE_SOURCE_RESOLUTION" | translate }}</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="aspect_ratio">
                                    {{ "ASPECT_RATIO" | translate }}
                                </label>
                                <ng-select
                                    id="aspect_ratio"
                                    name="aspect_ratio"
                                    [items]="show17_6Features ? consts.aspectRatioAdv : consts.aspectRatio"
                                    [clearable]="false"
                                    bindValue="value"
                                    bindLabel="name"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'ASPECT_RATIO' | translate }}"
                                    [(ngModel)]="transcodingProfile.aspect_ratio"
                                    (ngModelChange)="updateWidth()"
                                    #aspect_ratio="ngModel"
                                    [disabled]="transcodingProfile.source_resolution || transcodingProfile.keep_source_aspect_ratio"
                                    required
                                >
                                </ng-select>
                            </div>
                            <div class="row form-group">
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label class="me-2" for="width" [ngClass]="{ 'is-invalid': form.submitted && width.errors }">{{
                                                "WIDTH" | translate
                                            }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm w-100"
                                                id="width"
                                                name="width"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="transcodingProfile.width"
                                                min="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && width.errors }"
                                                [disabled]="transcodingProfile.source_resolution || transcodingProfile.aspect_ratio !== 'custom'"
                                                #width="ngModel"
                                            />
                                            <div *ngIf="width.invalid" class="invalid-feedback">
                                                <div *ngIf="width.errors.required">{{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="width.errors.pattern">
                                                    {{ "WIDTH" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label class="me-2" for="height" [ngClass]="{ 'is-invalid': form.submitted && height.errors }">{{
                                                "HEIGHT" | translate
                                            }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm w-100"
                                                id="height"
                                                name="height"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="transcodingProfile.height"
                                                min="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && height.errors }"
                                                (ngModelChange)="updateWidth()"
                                                [disabled]="transcodingProfile.source_resolution"
                                                #height="ngModel"
                                            />
                                            <div *ngIf="height.invalid" class="invalid-feedback">
                                                <div *ngIf="height.errors.required">{{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="height.errors.pattern">
                                                    {{ "HEIGHT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group" *ngIf="show17_6Features">
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label class="me-2" for="encoding_width" [ngClass]="{ 'is-invalid': form.submitted && height.errors }">{{
                                                "ENCODING_WIDTH" | translate
                                            }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control form-control-sm w-100"
                                                id="encoding_width"
                                                name="encoding_width"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="transcodingProfile.encoding_width"
                                                [ngClass]="{ 'is-invalid': form.submitted && encoding_width.errors }"
                                                [disabled]="transcodingProfile.source_resolution"
                                                #encoding_width="ngModel"
                                                min="16"
                                                max="{{ transcodingProfile.width }}"
                                            />
                                            <div *ngIf="encoding_width.invalid" class="invalid-feedback">
                                                <div *ngIf="encoding_width.errors.min">
                                                    {{ "ENCODING_WIDTH" | translate }} {{ "MUST_BE_GREATER_THAN_OR_EQUAL_TO" | translate }} 16.
                                                </div>
                                                <div *ngIf="encoding_width.errors.max">
                                                    {{ "ENCODING_WIDTH" | translate }} {{ "MUST_BE_LESS_THAN_OR_EQUAL_TO" | translate }}
                                                    {{ "WIDTH" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="sourceAspectRatio"
                                            name="sourceAspectRatio"
                                            [(ngModel)]="transcodingProfile.keep_source_aspect_ratio"
                                            [disabled]="transcodingProfile.source_resolution"
                                            (ngModelChange)="onSourceAspectRatioChange()"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="sourceAspectRatio"
                                            title="{{ 'TRANSCODING_WILL_ADD_BLACK_BARS_TO_MAINTAIN_SOURCE_ASPECT_RATIO' | translate }}"
                                            >{{ "MAINTAIN_SOURCE_ASPECT_RATIO" | translate
                                            }}<fa-icon icon="info-circle" [ngbTooltip]="ARContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                            ><ng-template #ARContent
                                                ><p class="mb-0">
                                                    {{ "WHEN_THIS_OPTION_IS_SELECTED_THE_UNUSED_SPACE_IS_FILLED_IN_WITH_BLACK_BARS" | translate }}
                                                </p></ng-template
                                            >
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="show17_6Features"
                                            name="show17_6Features"
                                            [(ngModel)]="show17_6Features"
                                            [disabled]="transcodingProfile.source_resolution || transcodingProfile.keep_source_aspect_ratio"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="show17_6Features"
                                            title="{{ 'REQUIRES_BROADCASTER_VERSION_17.6_OR_NEWER' | translate }}"
                                            >{{ "SHOW_ALL_ASPECT_RATIO" | translate
                                            }}<fa-icon icon="info-circle" [ngbTooltip]="allARContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                            ><ng-template #allARContent
                                                ><p class="mb-0">
                                                    {{ "REQUIRES_BROADCASTER_VERSION_17.6_OR_NEWER" | translate }}
                                                </p></ng-template
                                            >
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- FPS -->
                        <div class="form-group" *ngIf="videoMode === 0">
                            <label for="fps">
                                {{ "FPS" | translate }}
                            </label>
                            <ng-select
                                id="fps"
                                name="fps"
                                [items]="consts.videoFps"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_FPS' | translate }}"
                                [(ngModel)]="transcodingProfile.fps"
                                #fps="ngModel"
                                required
                            >
                            </ng-select>
                        </div>
                        <!-- VBR -->
                        <div class="form-group mt-3" *ngIf="videoMode === 0">
                            <div class="form-check form-check-inline">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="vbr"
                                    name="vbr"
                                    [(ngModel)]="transcodingProfile.vbr"
                                    (change)="updateVideoMaxBitrate()"
                                />
                                <label class="form-check-label" for="vbr">{{ "VBR" | translate }}</label>
                            </div>
                        </div>
                        <!-- Video Bitrate -->
                        <fieldset class="bordered mb-3" *ngIf="videoMode === 0">
                            <legend>{{ "VIDEO_BITRATE" | translate }}</legend>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label class="me-2" for="average" [ngClass]="{ 'is-invalid': form.submitted && average.errors }">{{
                                                "AVERAGE" | translate
                                            }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                id="average"
                                                name="average"
                                                placeholder="{{ 'KBPS' | translate }}"
                                                class="form-control w-100"
                                                [(ngModel)]="transcodingProfile.bitrate_avg"
                                                pattern="^\d+$"
                                                min="0"
                                                #average="ngModel"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && average.errors }"
                                            />
                                            <div *ngIf="average.invalid" class="invalid-feedback">
                                                <div *ngIf="average.errors.required">{{ "AVERAGE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="average.errors.pattern">
                                                    {{ "AVERAGE" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <label class="me-2" for="max" [ngClass]="{ 'is-invalid': form.submitted && max.errors }">{{
                                                "MAX" | translate
                                            }}</label>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                id="max"
                                                name="max"
                                                placeholder="{{ 'KBPS' | translate }}"
                                                class="form-control w-100"
                                                [(ngModel)]="transcodingProfile.bitrate_max"
                                                pattern="^\d+$"
                                                min="0"
                                                #max="ngModel"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && max.errors }"
                                                [disabled]="!transcodingProfile.vbr"
                                            />
                                            <div *ngIf="max.invalid" class="invalid-feedback">
                                                <div *ngIf="max.errors.required">{{ "MAX" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="max.errors.pattern">{{ "MAX" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- Performance -->
                        <div class="form-group" *ngIf="videoMode === 0 && transcodingProfile.video_codec !== 'mpeg2'">
                            <label for="performance">
                                {{ "PERFORMANCE" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="PerformanceContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #PerformanceContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TRANSCODING_PROFILE_PERFORMANCE" | translate }}
                                    </p></ng-template
                                >
                            </label>
                            <ng-select
                                id="performance"
                                name="performance"
                                [items]="consts.videoPerformances"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_PERFORMANCE' | translate }}"
                                [(ngModel)]="transcodingProfile.performance"
                                #performance="ngModel"
                                required
                            >
                            </ng-select>
                        </div>
                    </section>

                    <section id="advanced_video">
                        <!-- Advanced Video -->
                        <div class="toggle-fieldset-title" *ngIf="videoMode === 0" [ngClass]="{ closed: !showAdvancedVideo }">
                            <h3
                                *ngIf="videoMode === 0"
                                class="toggle-fieldset"
                                (click)="showAdvancedVideo = !showAdvancedVideo"
                                [ngClass]="[
                                    !showAdvancedVideo ? 'closed' : '',
                                    form.submitted && (form.controls.gop?.errors || form.controls.reference_frames?.errors || form.controls.hrd_buffer?.errors)
                                        ? 'is-invalid'
                                        : ''
                                ]"
                            >
                                {{ "ADVANCED_VIDEO" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvancedVideo"></fa-icon
                                ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvancedVideo"></fa-icon>
                            </h3>
                        </div>
                        <fieldset class="mb-3 bordered no-bg" *ngIf="videoMode === 0" [ngClass]="{ 'd-none': !showAdvancedVideo }">
                            <!-- GOP -->
                            <div class="form-group" *ngIf="videoMode === 0">
                                <label for="gop" class="mb-0">{{ "GOP" | translate }}</label>
                                <div class="input-group align-items-center">
                                    <div class="input-group-prepend" *ngIf="transcodingProfile.fps === 0">
                                        <div class="input-group">
                                            <div class="form-check form-check-inline">
                                                <input type="checkbox" class="form-check-input" id="sourceGop" name="sourceGop" [(ngModel)]="sourceGop" />
                                                <label class="form-check-label" for="sourceGop">{{ "SOURCE" | translate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-div" *ngIf="!sourceGop">
                                        <input
                                            type="number"
                                            id="gop"
                                            name="gop"
                                            placeholder="{{ 'FRAMES' | translate }}"
                                            class="form-control form-control-sm"
                                            [(ngModel)]="transcodingProfile.gop"
                                            pattern="^\d+$"
                                            min="0"
                                            #gop="ngModel"
                                            required
                                            [ngClass]="{ 'is-invalid': form.submitted && gop.errors }"
                                        />
                                        <div *ngIf="gop.invalid" class="invalid-feedback">
                                            <div *ngIf="gop.errors.required">{{ "GOP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                            <div *ngIf="gop.errors.pattern">{{ "GOP" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.</div>
                                        </div>
                                    </div>
                                    <div class="input-group-append" *ngIf="!sourceGop">
                                        <div class="input-group ms-3">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="gop_closed"
                                                    name="gop_closed"
                                                    [(ngModel)]="transcodingProfile.gop_closed"
                                                />
                                                <label class="form-check-label" for="gop_closed">{{ "CLOSED" | translate }}</label>
                                            </div>
                                        </div>
                                        <div class="input-group ms-3">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="gop_fixed"
                                                    name="gop_fixed"
                                                    [(ngModel)]="transcodingProfile.gop_fixed"
                                                />
                                                <label class="form-check-label" for="gop_fixed">{{ "FIXED" | translate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- B Frames -->
                            <div
                                class="form-group"
                                *ngIf="videoMode === 0 && transcodingProfile.encoding_profile !== 'base' && transcodingProfile.encoding_profile !== 'simple'"
                            >
                                <label for="b_frames">
                                    {{ "B_FRAMES" | translate }}
                                </label>
                                <ng-select
                                    id="b_frames"
                                    name="b_frames"
                                    class="form-control form-control-sm"
                                    [items]="consts.videoBFrames"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT_B_FRAMES' | translate }}"
                                    [(ngModel)]="transcodingProfile.b_frames"
                                    #b_frames="ngModel"
                                    required
                                >
                                </ng-select>
                            </div>
                            <!-- Interlaced -->
                            <div
                                class="form-group"
                                *ngIf="videoMode === 0 && transcodingProfile.encoding_profile !== 'base' && transcodingProfile.video_codec !== 'mpeg2'"
                            >
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="interlaced"
                                        name="interlaced"
                                        [(ngModel)]="transcodingProfile.interlaced"
                                    />
                                    <label class="form-check-label" for="interlaced">{{ "INTERLACED" | translate }}</label>
                                </div>
                            </div>
                            <!-- Reference Frames -->
                            <div class="form-group" *ngIf="videoMode === 0">
                                <label for="reference_frames" [ngClass]="{ 'is-invalid': form.submitted && reference_frames.errors }">{{
                                    "REFERENCE_FRAMES" | translate
                                }}</label>
                                <input
                                    type="number"
                                    id="reference_frames"
                                    name="reference_frames"
                                    placeholder="{{ 'FRAMES' | translate }}"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && reference_frames.errors }"
                                    [(ngModel)]="transcodingProfile.ref_frames"
                                    pattern="^\d+$"
                                    min="0"
                                    #reference_frames="ngModel"
                                    required
                                />
                                <div *ngIf="reference_frames.invalid" class="invalid-feedback">
                                    <div *ngIf="reference_frames.errors.required">{{ "REFERENCE_FRAMES" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="reference_frames.errors.pattern">
                                        {{ "REFERENCE_FRAMES" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                            <!-- HRD Buffer -->
                            <div class="form-group" *ngIf="videoMode === 0">
                                <label
                                    for="hrd_buffer"
                                    [ngClass]="{ 'is-invalid': form.submitted && hrd_buffer.errors }"
                                    *ngIf="transcodingProfile.video_codec !== 'mpeg2'"
                                    >{{ (transcodingProfile.video_codec !== "mpeg2" ? "HRD_BUFFER" : "VBV_BUFFER") | translate }}</label
                                >
                                <input
                                    type="number"
                                    id="hrd_buffer"
                                    name="hrd_buffer"
                                    placeholder="{{ 'SECONDS_D52' | translate }}"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && hrd_buffer.errors }"
                                    [(ngModel)]="transcodingProfile.hdr_buff_length"
                                    pattern="\d+(\.\d{1,2})?"
                                    min="0"
                                    max="100"
                                    #hrd_buffer="ngModel"
                                    required
                                />
                                <div *ngIf="hrd_buffer.invalid" class="invalid-feedback">
                                    <div *ngIf="hrd_buffer.errors.required">
                                        {{ (transcodingProfile.video_codec !== "mpeg2" ? "HRD_BUFFER" : "VBV_BUFFER") | translate }}
                                        {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                    <div *ngIf="hrd_buffer.errors.pattern">
                                        {{ (transcodingProfile.video_codec !== "mpeg2" ? "HRD_BUFFER" : "VBV_BUFFER") | translate }}
                                        {{ "MUST_BE_DECIMAL(5,2)" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Chroma Subsampling -->
                            <div
                                class="form-group"
                                *ngIf="videoMode === 0 && (transcodingProfile.video_codec !== 'mpeg2' || transcodingProfile.encoding_profile === 'high')"
                            >
                                <label for="chrma_subsampling">
                                    {{ "CHROMA_SUBSAMBPLING" | translate }}
                                </label>
                                <ng-select
                                    id="chrma_subsampling"
                                    name="chrma_subsampling"
                                    [items]="consts.chromaSubsampling"
                                    [clearable]="false"
                                    bindValue="value"
                                    bindLabel="name"
                                    [(ngModel)]="transcodingProfile.chroma_subsampling"
                                    required
                                >
                                </ng-select>
                                <small *ngIf="transcodingProfile.audio_sample_rate > 0 && transcodingProfile.audio_codec === 'aac'">
                                    {{ "REQUIRES_BROADCASTER_VERSION_13_OR_NEWER" | translate }}
                                </small>
                            </div>
                            <!-- Bit Depth -->
                            <div
                                class="form-group"
                                *ngIf="videoMode === 0 && transcodingProfile.encoding_profile === 'high10' && transcodingProfile.video_codec === 'h264'"
                            >
                                <label for="bit_depth">
                                    {{ "BIT_DEPTH" | translate }}
                                </label>

                                <ng-select
                                    id="bit_depth"
                                    name="bit_depth"
                                    [items]="consts.bitDepths"
                                    [clearable]="false"
                                    bindValue="value"
                                    bindLabel="name"
                                    [(ngModel)]="transcodingProfile.bit_depth"
                                    required
                                >
                                </ng-select>
                            </div>
                        </fieldset>
                    </section>

                    <section id="audio">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'AUDIO' | translate }}" [ngClass]="{ 'status-error': form.submitted && audioHasErrors(form) }">
                                {{ "AUDIO" | translate }}
                            </h3>
                        </div>
                        <fieldset class="form-group">
                            <legend class="sr-only" for="audioMode">{{ "AUDIO" | translate }}</legend>
                            <mat-button-toggle-group name="audioMode" aria-label="audio mode" [(ngModel)]="audioMode">
                                <mat-button-toggle [value]="0">{{ "TRANSCODE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="1">{{ "USE_ORIGINAL" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="2">{{ "REMOVE" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Audio Codec -->
                        <fieldset class="form-group" *ngIf="audioMode === 0">
                            <legend for="audio_codec">{{ "CODEC" | translate }}</legend>
                            <mat-button-toggle-group
                                name="audio_codec"
                                aria-label="audio codec"
                                [(ngModel)]="transcodingProfile.audio_codec"
                                (change)="updateAudioEncodingProfile()"
                            >
                                <mat-button-toggle value="aac">{{ "AAC" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="ac3">{{ "DOLBY_DIGITAL_PLUS" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="mpga1">{{ "MPGA" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                            <div *ngIf="transcodingProfile.audio_codec === 'ac3'">
                                <small>
                                    {{ "REQUIRES_BROADCASTER_VERSION_16_OR_NEWER" | translate }}
                                </small>
                            </div>
                            <div *ngIf="transcodingProfile.audio_codec === 'mpga1'">
                                <small>
                                    {{ "REQUIRES_BROADCASTER_VERSION_16.4_OR_NEWER" | translate }}
                                </small>
                            </div>
                        </fieldset>
                        <!-- Audio Profile -->
                        <div class="form-group" *ngIf="audioMode === 0 && transcodingProfile.audio_codec === 'aac'">
                            <label for="audio_profile">
                                {{ "AUDIO_PROFILE" | translate }}
                            </label>
                            <ng-select
                                id="audio_profile"
                                name="audio_profile"
                                [items]="consts.selectAudioProfiles"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_AUDIO_PROFILE' | translate }}"
                                [(ngModel)]="transcodingProfile.audio_encoder_profile"
                                #audio_profile="ngModel"
                                (ngModelChange)="updateAudioBitrate()"
                                required
                            >
                            </ng-select>
                        </div>
                        <!-- Audio Bitrate -->
                        <div class="form-group" *ngIf="audioMode === 0">
                            <label for="audio_bitrate">
                                {{ "AUDIO_BITRATE" | translate }}
                            </label>
                            <ng-select
                                id="audio_bitrate"
                                name="audio_bitrate"
                                [items]="consts.audioBitrates[transcodingProfile.audio_encoder_profile]"
                                [clearable]="false"
                                placeholder="{{ 'SELECT_AUDIO_BITRATE' | translate }}"
                                [(ngModel)]="transcodingProfile.audio_bitrate"
                                #audio_bitrate="ngModel"
                                required
                            >
                            </ng-select>
                        </div>
                        <!-- Audio Sampling Rate -->
                        <div class="form-group" *ngIf="audioMode === 0">
                            <label for="audio_sample_rate">
                                {{ "AUDIO_SAMPLING_RATE" | translate }}
                            </label>
                            <ng-select
                                id="audio_sample_rate"
                                name="audio_sample_rate"
                                [items]="consts.audioSampleRates"
                                [clearable]="false"
                                bindValue="value"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_AUDIO_SAMPLING_RATE' | translate }}"
                                [(ngModel)]="transcodingProfile.audio_sample_rate"
                                #audio_sample_rate="ngModel"
                                required
                                [disabled]="transcodingProfile.audio_codec === 'ac3'"
                            >
                            </ng-select>
                            <small *ngIf="transcodingProfile.audio_sample_rate > 0 && transcodingProfile.audio_codec === 'aac'">
                                {{ "REQUIRES_BROADCASTER_VERSION_13_OR_NEWER" | translate }}
                            </small>
                        </div>
                        <!-- Audio Gain -->
                        <div class="form-group" *ngIf="audioMode === 0">
                            <label for="audio_gain">
                                {{ "AUDIO_GAIN" | translate }}
                            </label>
                            <input
                                type="number"
                                id="audio_gain"
                                name="audio_gain"
                                class="form-control w-100"
                                [(ngModel)]="transcodingProfile.audio_gain"
                                pattern="^([0-9](\.\d*)?|10(\.\d*)?)$"
                                min="0"
                                max="10"
                                step="0.01"
                                #audio_gain="ngModel"
                                required
                            />
                            <small *ngIf="transcodingProfile.audio_gain !== 1.0">
                                {{ "REQUIRES_BROADCASTER_VERSION_17.2_OR_NEWER" | translate }}
                            </small>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
