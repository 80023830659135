import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { TranscodingProfilesService } from "../transcoding-profiles.service";
import { TranscodingProfile } from "../transcoding-profile";
import { SharedService } from "src/app/services/shared.service";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-transcoding-profile-form",
    templateUrl: "./transcoding-profile-form.component.html",
    providers: [TitleCasePipe]
})
export class TranscodingProfileFormComponent implements OnInit, OnDestroy {
    transcodingProfile: TranscodingProfile;
    transcodingProfileName: string;
    transcodingProfileNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    constants = Constants;

    loading = true;
    saving = false;
    showAdvancedVideo = false;
    show17_6Features = false;

    consts = Constants;
    videoMode: number;
    audioMode: number;
    sourceGop: boolean;

    currentSection = "info";

    private transcodingProfilesSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private tps: TranscodingProfilesService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.transcodingProfileName = params.get("name");
            this.action = params.get("action");
            if (this.transcodingProfileName) {
                this.transcodingProfile = Object.assign(
                    {},
                    this.tps.getCachedTranscodingProfile(this.transcodingProfileName)
                );
                // Check if found in cache
                if (this.sharedService.isEmptyObject(this.transcodingProfile)) {
                    this.tps
                        .refreshTranscodingProfiles(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.transcodingProfile = Object.assign(
                                {},
                                this.tps.getCachedTranscodingProfile(this.transcodingProfileName)
                            );
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    updateWidth() {
        if (this.transcodingProfile.aspect_ratio !== "custom") {
            const [ar_w, ar_h] = this.transcodingProfile.aspect_ratio.split(":").map(Number);
            const calculated_width = Math.ceil((this.transcodingProfile.height * ar_w) / ar_h / 2) * 2;
            this.transcodingProfile.width = calculated_width;
        }
    }

    onSourceAspectRatioChange() {
        if (this.transcodingProfile.keep_source_aspect_ratio) this.transcodingProfile.aspect_ratio = "custom";
    }

    prepForm() {
        if (this.isClone || this.isEdit) {
            if (this.isClone) this.transcodingProfile.name = null;

            if (!this.transcodingProfile.fps) this.transcodingProfile.fps = 0;

            this.transcodingProfile.bitrate_max = this.transcodingProfile.bitrate_max || null;

            this.sourceGop = this.transcodingProfile.gop == null;
            this.videoMode = this.transcodingProfile.do_video ? 0 : this.transcodingProfile.keep_video ? 1 : 2;
            this.audioMode = this.transcodingProfile.do_audio ? 0 : this.transcodingProfile.keep_audio ? 1 : 2;
            this.transcodingProfile.audio_sample_rate = this.transcodingProfile.audio_sample_rate || 0;
            this.transcodingProfile.bit_depth = this.transcodingProfile.bit_depth || 0;
            if (
                !this.constants.aspectRatio.find(ar => ar.value === this.transcodingProfile.aspect_ratio) ||
                this.transcodingProfile.encoding_width > 0
            )
                this.show17_6Features = true;
            else this.show17_6Features = false;
        } else if (!this.transcodingProfile) {
            this.transcodingProfile = new TranscodingProfile();
            this.transcodingProfile.name = null;
            this.transcodingProfile.vbr = true;
            //
            this.videoMode = 0;
            this.transcodingProfile.video_codec = "h264";
            this.transcodingProfile.encoding_profile = "main";
            this.transcodingProfile.fps = 0;
            this.transcodingProfile.gop = 30;
            this.sourceGop = false;
            this.transcodingProfile.gop_closed = true;
            this.transcodingProfile.gop_fixed = true;
            this.transcodingProfile.performance = 4;
            this.transcodingProfile.b_frames = 3;
            this.transcodingProfile.ref_frames = 2;
            this.transcodingProfile.hdr_buff_length = 2;
            //
            this.audioMode = 0;
            this.transcodingProfile.audio_codec = "aac";
            this.transcodingProfile.audio_encoder_profile = "low";
            this.transcodingProfile.audio_bitrate = 80;
            this.transcodingProfile.audio_sample_rate = 0;
            this.transcodingProfile.audio_gain = 1.0;
            this.transcodingProfile.chroma_subsampling = 0;
            this.transcodingProfile.bit_depth = 0;
            this.transcodingProfile.aspect_ratio = "custom";
            this.show17_6Features = false;
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("TRANSCODING_PROFILE") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.transcodingProfile && this.transcodingProfile.name ? this.transcodingProfile.name : "")
        );
    }

    ngOnInit() {
        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        this.tps.refreshTranscodingProfiles();

        this.transcodingProfilesSubscription = this.tps.transcodingProfiles.subscribe(
            (transcodingProfiles: TranscodingProfile[]) => {
                this.transcodingProfileNames = _.map(transcodingProfiles, "profile");
                if (this.isEdit) {
                    this.transcodingProfileNames = _.without(this.transcodingProfileNames, this.transcodingProfileName);
                }
            }
        );

        this.prepForm();
    }

    ngOnDestroy() {
        this.transcodingProfilesSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            profile: this.transcodingProfile.name,
            encoding_profile: this.transcodingProfile.encoding_profile,
            hardware_accel: false,
            height: this.transcodingProfile.height || null,
            width: this.transcodingProfile.width || null,
            fps: this.transcodingProfile.fps,
            bitrate_avg: this.transcodingProfile.bitrate_avg,
            bitrate_max: this.transcodingProfile.bitrate_max || 0,
            performance: this.transcodingProfile.performance,
            audio_encoder_profile:
                this.transcodingProfile.audio_codec === "aac" ? this.transcodingProfile.audio_encoder_profile : null,
            audio_bitrate: this.transcodingProfile.audio_bitrate,
            audio_sample_rate: this.transcodingProfile.audio_sample_rate,
            audio_gain: this.transcodingProfile.audio_gain,
            do_video: this.videoMode === 0,
            do_audio: this.audioMode === 0,
            keep_video: this.videoMode === 1,
            keep_audio: this.audioMode === 1,
            video_codec: this.transcodingProfile.video_codec,
            audio_codec: this.transcodingProfile.audio_codec,
            gop: this.sourceGop ? null : this.transcodingProfile.gop,
            gop_closed: !!this.transcodingProfile.gop_closed,
            gop_fixed: !!this.transcodingProfile.gop_fixed,
            b_frames: this.transcodingProfile.b_frames,
            interlaced: !!this.transcodingProfile.interlaced,
            ref_frames: this.transcodingProfile.ref_frames,
            hdr_buff_length: this.transcodingProfile.hdr_buff_length,
            keep_source_aspect_ratio: !!this.transcodingProfile.keep_source_aspect_ratio,
            source_resolution: !!this.transcodingProfile.source_resolution,
            chroma_subsampling: this.transcodingProfile.chroma_subsampling,
            bit_depth:
                this.transcodingProfile.encoding_profile === "high10" ? this.transcodingProfile.bit_depth : undefined,
            aspect_ratio: this.transcodingProfile.aspect_ratio,
            vbr: Boolean(this.transcodingProfile.vbr),
            encoding_width: this.transcodingProfile.encoding_width || null
        };

        if (this.isEdit) {
            const result = await this.tps.updateTranscodingProfile(this.transcodingProfile, model);
            if (result)
                this.router.navigate([
                    Constants.urls.transformation.transcoding_profiles,
                    this.transcodingProfile.name
                ]);
        } else {
            const result = await this.tps.addTranscodingProfile(model);
            if (result) this.router.navigate([Constants.urls.transformation.transcoding_profiles, model.profile]);
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate([Constants.urls.transformation.transcoding_profiles, this.transcodingProfileName]);
        else this.router.navigate([Constants.urls.transformation.transcoding_profiles]);
    }

    updateEncodingProfile() {
        if (this.transcodingProfile.video_codec === "h264") {
            if (this.transcodingProfile.encoding_profile === "main10")
                this.transcodingProfile.encoding_profile = "main";
        } else if (this.transcodingProfile.video_codec === "h265") {
            if (
                this.transcodingProfile.encoding_profile === "base" ||
                this.transcodingProfile.encoding_profile === "high" ||
                this.transcodingProfile.encoding_profile === "high10"
            )
                this.transcodingProfile.encoding_profile = "main";
        }
        this.transcodingProfile.vbr = Boolean(this.transcodingProfile.video_codec !== "mpeg2");
        this.updateVideoMaxBitrate();
    }

    updateSourceAspectRatio() {
        if (this.transcodingProfile.source_resolution) this.transcodingProfile.keep_source_aspect_ratio = true;
        else this.transcodingProfile.keep_source_aspect_ratio = false;
    }

    updateAudioEncodingProfile() {
        if (this.transcodingProfile.audio_codec === "ac3") {
            this.transcodingProfile.audio_encoder_profile = "ac3";
            this.transcodingProfile.audio_sample_rate = 48000;
        } else {
            this.transcodingProfile.audio_encoder_profile = "low";
        }

        this.updateAudioBitrate();
    }

    updateAudioBitrate() {
        this.transcodingProfile.audio_bitrate =
            this.consts.audioBitrates[this.transcodingProfile.audio_encoder_profile][0];
    }

    encodingProfileChanged() {
        if (this.transcodingProfile.encoding_profile === "base") {
            this.transcodingProfile.b_frames = 0;
            this.transcodingProfile.interlaced = false;
        }
        if (this.transcodingProfile.video_codec === "mpeg2" && this.transcodingProfile.encoding_profile === "simple") {
            this.transcodingProfile.b_frames = 0;
            this.transcodingProfile.interlaced = false;
        }
    }

    // Error Code
    videoHasErrors(form: NgForm) {
        if (form.form.controls.name && form.form.controls.name.invalid === true) return true;
        if (form.form.controls.width && form.form.controls.width.invalid === true) return true;
        if (form.form.controls.height && form.form.controls.height.invalid === true) return true;
        if (form.form.controls.average && form.form.controls.average.invalid === true) return true;
        if (form.form.controls.max && form.form.controls.max.invalid === true) return true;
        if (this.adVideoHasErrors(form)) return true;
        else return false;
    }

    adVideoHasErrors(form: NgForm) {
        if (form.form.controls.gop && form.form.controls.gop.invalid === true) return true;
        if (form.form.controls.reference_frames && form.form.controls.reference_frames.invalid === true) return true;
        if (form.form.controls.hrd_buffer && form.form.controls.hrd_buffer.invalid === true) return true;
        return false;
    }

    audioHasErrors() {
        return false;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
    }

    updateVideoMaxBitrate() {
        if (!this.transcodingProfile.vbr) {
            this.transcodingProfile.bitrate_max = null;
        }
    }

    onVideoModeChange() {
        this.transcodingProfile.vbr = this.videoMode === 0;
    }
}
