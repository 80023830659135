import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { TranscodingProfileComponent } from "./transcoding-profile/transcoding-profile.component";
import { TranscodingProfileFormComponent } from "./transcoding-profile-form/transcoding-profile-form.component";
import { TranscodingProfileListComponent } from "./transcoding-profile-list/transcoding-profile-list.component";

// Routes
import { TranscodingProfilesRouting } from "./transcoding-profiles.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        TranscodingProfilesRouting
    ],
    declarations: [TranscodingProfileComponent, TranscodingProfileFormComponent, TranscodingProfileListComponent]
})
export class TranscodingProfilesModule {}
