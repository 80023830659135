import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
//
import { TranscodingProfilesService } from "../transcoding-profiles.service";
import { TranscodingProfile } from "../transcoding-profile";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { ChromaMapperPipe } from "../../../pipes/chroma-mapper.pipe";

@Component({
    selector: "app-transcoding-profile",
    templateUrl: "./transcoding-profile.component.html",
    providers: [ChromaMapperPipe]
})
export class TranscodingProfileComponent implements OnInit, OnDestroy {
    transcodingProfile: TranscodingProfile;
    transcodingProfileName: string;

    private transcodingProfilesSubscription: Subscription;

    constants = Constants;

    loadingDetails = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private tps: TranscodingProfilesService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.transcodingProfileName = params.get("name");
            if (this.transcodingProfileName)
                this.transcodingProfile = this.tps.getCachedTranscodingProfile(this.transcodingProfileName);
            if (!this.transcodingProfile) return this.cancel();

            // Set Title
            this.titleService.setTitle(
                this.translate.instant("TRANSCODING_PROFILE") + " - " + this.transcodingProfile.name
            );

            // Loaded Details?
            if (!this.transcodingProfile.hasFullDetails) this.loadingDetails = true;
            this.tps.refreshTranscodingProfile(this.transcodingProfileName, true);
        });
    }

    ngOnInit() {
        this.transcodingProfilesSubscription = this.tps.transcodingProfiles.subscribe(transcodingProfiles => {
            this.transcodingProfile = transcodingProfiles.find(
                (tp: TranscodingProfile) => tp.name === this.transcodingProfileName
            );
            if (this.transcodingProfile && this.transcodingProfile.hasFullDetails) this.loadingDetails = false;
        });
    }

    ngOnDestroy() {
        if (this.transcodingProfilesSubscription) this.transcodingProfilesSubscription.unsubscribe();
    }

    cancel() {
        this.gotoTranscodingProfiles();
    }

    gotoTranscodingProfiles() {
        this.router.navigate([Constants.urls.transformation.transcoding_profiles]);
    }

    async deleteTranscodingProfile() {
        await this.modalService.confirm(
            "DELETE",
            "TRANSCODING_PROFILE",
            async () => {
                const id = this.transcodingProfile.id;
                const result = await this.tps.deleteTranscodingProfile(this.transcodingProfile);
                if (result) {
                    this.mixpanelService.sendEvent("delete transcoding profile", { id });
                    this.gotoTranscodingProfiles();
                } else {
                    return false;
                }
            },
            this.transcodingProfile.name
        );
    }

    editTranscodingProfile(profile: string): void {
        this.router.navigate([Constants.urls.transformation.transcoding_profiles, profile, "edit"]);
    }

    cloneTranscodingProfile(profile: string): void {
        this.router.navigate([Constants.urls.transformation.transcoding_profiles, profile, "clone"]);
    }

    refreshTranscodingProfilesPromise() {
        return firstValueFrom(this.tps.refreshTranscodingProfile(this.transcodingProfileName, true));
    }
}
