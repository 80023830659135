<!-- Details Panel -->
<div class="details-panel" *ngIf="transcodingProfile">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ transcodingProfile.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                >{{ transcodingProfile.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button
                                (click)="editTranscodingProfile(transcodingProfile.name)"
                                class="text-primary"
                                title="{{ 'EDIT' | translate }}"
                                mat-menu-item
                            >
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button (click)="cloneTranscodingProfile(transcodingProfile.name)" class="text-primary" mat-menu-item>
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button (click)="deleteTranscodingProfile()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item>
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editTranscodingProfile(transcodingProfile.name)"
                        title="{{ 'EDIT' | translate }}"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-primary" (click)="cloneTranscodingProfile(transcodingProfile.name)">
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deleteTranscodingProfile()" title="{{ 'DELETE' | translate }}">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col-12" [ngClass]="{ 'col-xxl-6': transcodingProfile.do_video }">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>VIDEO_CODEC</h5>
                                            <div class="col-12">
                                                <span *ngIf="transcodingProfile.video_codec === 'h264'">{{ "H.264" | translate }}</span>
                                                <span *ngIf="transcodingProfile.video_codec === 'h265'">{{ "H.265" | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>ENCODING_PROFILE</h5>
                                            <div class="col-12">
                                                <span *ngIf="transcodingProfile.video_codec === 'h264'">{{
                                                    constants.videoProfiles[transcodingProfile.encoding_profile]
                                                }}</span>
                                                <span *ngIf="transcodingProfile.video_codec === 'h265'">{{
                                                    constants.videoProfilesH265[transcodingProfile.encoding_profile]
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>RESOLUTION</h5>
                                            <div class="col-12">
                                                <span *ngIf="transcodingProfile.width || transcodingProfile.width === 0"
                                                    >{{ transcodingProfile.width }}x{{ transcodingProfile.height }}</span
                                                >
                                                <span *ngIf="!transcodingProfile.width && transcodingProfile.width !== 0">{{
                                                    "USE_SOURCE_RESOLUTION" | translate
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>FPS</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.fps || ("ORIGINAL" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.gop">
                                            <h5 class="col-12" translate>GOP</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.gop }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.gop">
                                            <h5 class="col-12" translate>CLOSED_GOP</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.gop_closed ? ("YES" | translate) : ("NO" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.gop">
                                            <h5 class="col-12" translate>FIXED_GOP</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.gop_fixed ? ("YES" | translate) : ("NO" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>VBR</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.vbr ? ("YES" | translate) : ("NO" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>AVERAGE_VIDEO_BITRATE</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.bitrate_avg | number : "1.0-0" }} kbps</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>MAX_VIDEO_BITRATE</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.bitrate_max | number : "1.0-0" }} kbps</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video">
                                            <h5 class="col-12" translate>PERFORMANCE</h5>
                                            <div class="col-12">
                                                <span>{{ constants.videoPerformances[transcodingProfile.performance].name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" [ngClass]="{ 'col-xxl-6': transcodingProfile.do_video }">
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.b_frames">
                                            <h5 class="col-12" translate>B_FRAMES</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.b_frames }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.interlaced">
                                            <h5 class="col-12" translate>INTERLACED</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.interlaced ? ("YES" | translate) : ("NO" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.ref_frames">
                                            <h5 class="col-12" translate>REFERENCE_FRAMES</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.ref_frames }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.hdr_buff_length">
                                            <h5 class="col-12" translate>HRD_BUFFER</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.hdr_buff_length }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_video && transcodingProfile.chroma_subsampling">
                                            <h5 class="col-12" translate>CHROMA_SUBSAMBPLING</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.chroma_subsampling | chromaMapper }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="!transcodingProfile.do_video">
                                            <h5 class="col-12" translate>VIDEO</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.keep_video ? ("ORIGINAL" | translate) : ("REMOVE" | translate) }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_audio">
                                            <h5 class="col-12" translate>AUDIO_CODEC</h5>
                                            <div class="col-12">
                                                <span *ngIf="transcodingProfile.audio_codec === 'aac'">{{ "AAC" | translate }}</span>
                                                <span *ngIf="transcodingProfile.audio_codec === 'ac3'">{{ "DOLBY_DIGITAL_PLUS" | translate }}</span>
                                                <span *ngIf="transcodingProfile.audio_codec === 'mpga1'">{{ "MPGA" | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_audio && transcodingProfile.audio_codec === 'aac'">
                                            <h5 class="col-12" translate>AUDIO_PROFILE</h5>
                                            <div class="col-12">
                                                <span>{{ constants.audioProfiles[transcodingProfile.audio_encoder_profile] }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_audio">
                                            <h5 class="col-12" translate>AUDIO_BITRATE</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.audio_bitrate | number : "1.0-0" }} kbps</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_audio">
                                            <h5 class="col-12" translate>AUDIO_SAMPLING_RATE</h5>
                                            <div class="col-12">
                                                <span *ngIf="transcodingProfile._frontData.prettyAudioSampleRate">{{
                                                    transcodingProfile._frontData.prettyAudioSampleRate.name
                                                }}</span>
                                                <span *ngIf="!transcodingProfile._frontData.prettyAudioSampleRate">{{ "ORIGINAL" | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="transcodingProfile.do_audio">
                                            <h5 class="col-12" translate>AUDIO_GAIN</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.audio_gain }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="!transcodingProfile.do_audio">
                                            <h5 class="col-12" translate>AUDIO</h5>
                                            <div class="col-12">
                                                <span>{{ transcodingProfile.keep_audio ? ("ORIGINAL" | translate) : ("REMOVE" | translate) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- Change Tracking -->
                <ngb-accordion #changeTrackingAccordion="ngbAccordion" activeIds="ngb-panel-change-tracking" class="no-padding">
                    <ngb-panel id="ngb-panel-change-tracking">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'CHANGE_TRACKING' | translate }}">CHANGE_TRACKING</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-object-tracking-main-list
                                #objectTrackingList
                                [objectId]="transcodingProfile.id"
                                [objectName]="transcodingProfile.name"
                                objectType="transcoding_profile"
                                objectApiType="transcoding_profiles"
                                [refreshObjectDataFunction]="refreshTranscodingProfilesPromise.bind(this)"
                                [autoRows]="false"
                                [includeRestartConfirmation]="false"
                            ></zx-object-tracking-main-list>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
